// useTrackPageChange.ts
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from './useAnalytics';

export function useTrackPageChange() {
	const location = useLocation();
	const analytics = useAnalytics();

	useEffect(() => {
		analytics.trackPageChange(location.pathname);
	}, [location]);
}
