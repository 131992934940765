import React, { useState } from 'react';
import { useTimeout } from '../../hooks/useTimeout';
import bear_sad from '../../images/Karhu-tyytymätön- sininen.svg';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { Tooltip } from '@mui/material';

type DelaySpinnerType =
	| 'relative'
	| 'small'
	| 'small-relative'
	| 'absolute'
	| 'absolute-list'
	| 'is-loading'
	| 'default';

interface Props {
	type?: DelaySpinnerType;
	delay?: number;
	error?: any;
}

export function DelaySpinner({ type = 'default', delay = 300, error }: Props) {
	const [showSpinner, setShowSpinner] = useState(false);
	const { t } = useTypedTranslation();
	useTimeout(() => setShowSpinner(true), delay);
	if (error)
		return (
			<Tooltip title={t('error.generic')}>
				<div className={`spinner-error ${getSpinnerClass(type)}`}>
					<img
						alt="bear_icon"
						style={{
							height: '100%',
							width: '100%',
							maxWidth: 64,
							maxHeight: 64,
							position: 'relative'
						}}
						src={bear_sad}
					/>
				</div>
			</Tooltip>
		);

	if (!showSpinner) return null;
	return <div className={`element ${getSpinnerClass(type)}`} />;
}

function getSpinnerClass(type: DelaySpinnerType) {
	switch (type) {
		case 'relative':
			return 'is-loading-relative';
		case 'small':
			return 'is-loading-small';
		case 'small-relative':
			return 'is-loading-small-relative';
		case 'absolute':
			return 'is-loading-absolute';
		case 'absolute-list':
			return 'is-loading-absolute-list';
		default:
			return 'is-loading';
	}
}

//TODO get rid of default export
export default DelaySpinner;
