import { MyOrganizationsModel } from '../models/Organization';
import { PermissionModel } from '../models/Permission';
import { UserModel } from '../models/User';
import { freesiAxiosFactory } from './freesiAxiosFactory';
import { basicGet } from './utils/basicRestOperations';

/**
 * Factory for building Axios+HTTP based WhoAmI REST API clients.
 * NOTE! All methods will throw on non-2xx responses.
 * @param token Keycloak access token.
 * @returns WhoamiApi which can be used for querying the Freesi cloud service WhoamiApi end-point.
 */
export function whoamiApiFactory(token: string | undefined) {
	const axios = freesiAxiosFactory('cloud', token);
	return {
		whoami: () => basicGet<UserModel>(axios, `/whoami`),
		myOrganizations: () => basicGet<MyOrganizationsModel>(axios, `/whoami/organizations`),
		myPermissions: () => basicGet<PermissionModel>(axios, `/whoami/permissions`)
	};
}
