import { create } from 'zustand';
import { isNil } from '../../utils/typeGuards';
import { whoamiApiFactory } from '../../api/whoamiApi';
import { buildTheme, SupportedTheme } from '../../brands/brandUtilities';
import { MyOrganizationsModel, SimpleOrganizationModel } from '../../models/Organization';
import { Theme } from '../../brands/Theme';
import { ApplicationPermission } from '../../models/ApplicationPermission';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { isTestContext } from '../../test/utils/isTestContext';
import { isDevMode } from '../../test/utils/isDevMode';
import { UserModel } from '../../models/User';
import { RoleType } from '../../models/Role';
import { SupportedLocale } from '../../models/LocalizedString';
import { defaultLocale } from '../../i18n/getLocaleStrings';

interface State {
	token?: string;
	activePalette: string;
	currentTheme?: Theme;
	currentUser?: UserModel;
	currentRole?: RoleType;
	currentLocale?: SupportedLocale;
	currentPermissions: ApplicationPermission[];
	currentOrganizations?: MyOrganizationsModel;
	activeOrganization?: SimpleOrganizationModel;
	orgChangeListeners: (() => Promise<void>)[];
	orgChangeListenersLoading?: boolean;
	externalToken?: string;
	externalUserEmail?: string;
}

interface Actions {
	setToken: (token?: string) => void;
	setLocale: (locale?: SupportedLocale) => void;
	refreshUser: () => Promise<void>;
	setActiveOrganization: (activeOrganization: SimpleOrganizationModel) => void;
	hasRole: (role: RoleType) => boolean;
	hasPermission: (type: ApplicationPermission) => boolean;
	addOrgChangeListener: (listener: () => Promise<void>) => void;
	setExternalToken: (token: string) => void;
	setExternalUserEmail: (email: string) => void;
}

export const useSessionStore = create<State & Actions>((set, get) => ({
	activePalette: 'freesi',
	currentPermissions: [],
	orgChangeListeners: [],
	currentLocale: defaultLocale,
	setToken(token) {
		set({ token });
	},
	setLocale(locale) {
		set({ currentLocale: locale });
	},
	async refreshUser() {
		const token = get().token;
		const { currentUser, myOrganizations } = await refreshMyDetailsAndOrganizations(token);
		const activePalette = pickPalette(myOrganizations);
		set({
			activePalette,
			currentTheme: await buildTheme(activePalette),
			currentUser,
			currentOrganizations: myOrganizations,
			activeOrganization: myOrganizations.main
		});

		const { myPermissions } = await refreshMyPermissions(token);
		set({
			currentRole: myPermissions.activeRole,
			currentPermissions: myPermissions.permissions
		});
	},
	async setActiveOrganization(activeOrganization) {
		const token = get().token;
		set({
			activeOrganization
		});
		await Promise.all(get().orgChangeListeners.map(l => l()));
		const { myPermissions } = await refreshMyPermissions(token);
		set({
			currentRole: myPermissions.activeRole,
			currentPermissions: myPermissions.permissions,
			orgChangeListenersLoading: true
		});

		set({
			orgChangeListenersLoading: false
		});
	},
	hasRole(role) {
		return get().currentRole === role;
	},
	hasPermission(permission) {
		return get().currentPermissions.includes(permission);
	},
	addOrgChangeListener(listener) {
		set({
			orgChangeListeners: [...get().orgChangeListeners, listener]
		});
	},
	setExternalToken(token) {
		set({ externalToken: token });
	},
	setExternalUserEmail(email) {
		set({ externalUserEmail: email });
	}
}));

function pickPalette(myOrganizations: MyOrganizationsModel) {
	const palette = myOrganizations.main.themePalette;
	return !isNil(palette) ? palette : SupportedTheme.Freesi;
}

const MOCK_ORGANIZATION = 'MOCK_ORGANIZATION';

export function getActiveOrganizationId() {
	if (isTestContext()) return MOCK_ORGANIZATION;
	return useSessionStore.getState().activeOrganization?.id;
}

const MOCK_TOKEN = 'MOCK_TOKEN';

export function getSessionToken() {
	if (isTestContext()) return MOCK_TOKEN;
	return useSessionStore.getState().token;
}

export function getSessionStoreState() {
	return useSessionStore.getState();
}

export function refreshUser() {
	return useSessionStore.getState().refreshUser();
}

export function addOrgChangeListener(listener: (() => Promise<void>) | (() => void)) {
	const wrappedListener = async () => {
		const result = listener();
		if (result instanceof Promise) {
			await result;
		}
	};
	useSessionStore.getState().addOrgChangeListener(wrappedListener);
}

async function refreshMyDetailsAndOrganizations(token?: string) {
	const api = whoamiApiFactory(token);
	const currentUser = await api.whoami();
	const myOrganizations = await api.myOrganizations();
	return { currentUser, myOrganizations };
}

async function refreshMyPermissions(token?: string) {
	const api = whoamiApiFactory(token);
	const myPermissions = await api.myPermissions();
	return { myPermissions };
}

if (isDevMode()) mountStoreDevtool('SessionStore', useSessionStore);
