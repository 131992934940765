// TODO: Either start moving theme related information into this file or get rid of it.

import { createTheme } from '@mui/material/styles';

export default createTheme({
	palette: {
		primary: {
			main: '#00195F'
		}
	},
	typography: {
		button: {
			fontWeight: 400,
			fontFamily: 'Poppins'
		}
	}
});
