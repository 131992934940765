import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLocaleStrings } from './i18n/getLocaleStrings';

i18n.use(initReactI18next).init({
	resources: {
		fi: { translation: getLocaleStrings('fi') },
		en: { translation: getLocaleStrings('en') },
		sv: { translation: getLocaleStrings('sv') },
		de: { translation: getLocaleStrings('de') }
	},
	fallbackLng: 'en',
	debug: false,
	interpolation: {
		escapeValue: false
	}
});

export default i18n;
