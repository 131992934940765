export const loadGoogleAnalytics = () => {
	const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
	if (!trackingId) {
		console.debug('Google Analytics ID is missing.');
		return;
	}

	// @ts-ignore
	window.dataLayer = window.dataLayer || [];

	function gtag() {
		// @ts-ignore
		dataLayer.push(arguments);
	}

	// @ts-ignore
	window.gtag = gtag;
	// @ts-ignore
	gtag('js', new Date());
	// @ts-ignore
	gtag('config', trackingId);

	const script = document.createElement('script');
	script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
	script.async = true;
	document.head.appendChild(script);
};
