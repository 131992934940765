import axios, { AxiosInstance } from 'axios';
import { SupportedLocale } from '../models/LocalizedString';
import { useSessionStore } from '../stores/session/SessionStore';
import { isEmpty } from '../utils/helperFunctions';
import { apiSettings, FreesiService } from './apiSettings';
import { addLoggingInterceptors } from './utils/logInterception';

/**
 * Freesi Axios factory method creates axios client for the given Freesi Service.
 *
 * @param service Freesi service to target.
 * @param token Bearer token value to use for authentication. Set undefined for anonymous queries.
 * @param currentOrganizationId Currently selected organization
 * @returns Axios instance
 */
export function freesiAxiosFactory(
	service: FreesiService,
	token?: string,
	currentOrganizationId?: string
): AxiosInstance {
	const effectiveSettings = apiSettings;
	const session = useSessionStore.getState();
	const freesiCloudAxios = axios.create({
		baseURL: `${effectiveSettings.apiBaseUrl}/${service}`,
		headers: defaultHeaderFactory(
			token ?? session.token,
			currentOrganizationId ?? session.activeOrganization?.id,
			session.currentLocale
		)
	});
	if (effectiveSettings.apiCallLoggingEnabled) {
		addLoggingInterceptors(freesiCloudAxios);
	}
	return freesiCloudAxios;
}

type Headers = {
	'Content-Type': string;
	Authorization?: string;
	'X-Current-Organization-Id'?: string;
	'X-Current-Locale'?: string;
};
const defaultHeaderFactory = (
	token: string | undefined,
	currentOrganizationId: string | undefined,
	currentLocale: SupportedLocale | undefined
) => {
	const headers: Headers = {
		'Content-Type': 'application/json'
	};
	if (!isEmpty(token)) {
		headers['Authorization'] = `Bearer ${token}`;
	}
	if (!isEmpty(currentOrganizationId)) {
		headers['X-Current-Organization-Id'] = currentOrganizationId;
	}
	if (!isEmpty(currentLocale)) {
		headers['X-Current-Locale'] = currentLocale;
	}

	return headers;
};
