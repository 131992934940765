import { useEffect } from 'react';

export function useRedirect() {
	useEffect(() => {
		const redirectRule = process.env.REACT_APP_REDIRECT_HOSTS;
		if (redirectRule === undefined) return;
		try {
			const sourceHosts = redirectRule.split(',');
			const targetHost = process.env.REACT_APP_KEYCLOAK_HOST;
			const host = window.location.host;
			if (sourceHosts.includes(host)) {
				console.log(`Redirecting from '${host}' to '${targetHost}'.`);
				const proto = window.location.protocol;
				window.location.replace(`${proto}//${targetHost}`);
			}
		} catch {}
	}, []);
}
