import { isArray, isNil, isString } from './typeGuards';
import { Order } from '../models/sharedTypes';
import _ from 'lodash';

export const noOperation = (): void => {};

export const isUrl = (string: string | undefined | null): boolean => {
	if (isNil(string)) return false;
	try {
		new URL(string);
		return true;
	} catch (_) {
		return false;
	}
};

/**
 * Checks if `object` is empty.
 * @param object Object to check.
 * @returns True if `object` is `nil`, an empty string or an empty array.
 */
export const isEmpty = (object: any): boolean => {
	if (isNil(object)) return true;
	if (isString(object)) return object.trim().length === 0;
	if (isArray(object)) return object.length === 0;
	return false;
};

/**
 * Checks if `values` is a subset of `set`.
 */
export const isSubsetOf = (values: string[], set: string[]): boolean => {
	for (const value of values) {
		if (!set.includes(value)) {
			return false;
		}
	}
	return true;
};

/**
 * Sort items in ascending order by a given property.
 * @param items Items to sort.
 * @param prop Item property to sort on.
 * @returns An array of items sorted in ascending order.
 */
export function sortAscBy<T>(items: T[], prop: keyof T): T[] {
	const arr = [...items];
	return arr.sort((a, b) => (a[prop] < b[prop] ? -1 : a[prop] > b[prop] ? 1 : 0));
}

export function sortDescBy<T>(items: T[], prop: keyof T): T[] {
	const arr = [...items];
	return arr.sort((a, b) => (a[prop] < b[prop] ? 1 : a[prop] > b[prop] ? -1 : 0));
}

export function sortByOrder<T>(items: T[], prop: keyof T, order: Order): T[] {
	if (order === 'asc') return sortAscBy(items, prop);
	else return sortDescBy(items, prop);
}

export function sortByScore<T>(items: T[], scoreFn: (item: T) => number, order: Order): T[] {
	const sign = order === 'asc' ? 1 : -1;
	return _.sortBy(items, i => scoreFn(i) * sign);
}

/**
 * Extracts field values from an array.
 *
 * @param items Items whose values to extract.
 * @param field Name of the field to extract.
 * @returns An array of extracted items.
 */
export const extractValues = <T, P extends keyof T>(items: T[] | undefined | null, field: P): T[P][] => {
	return isNil(items) || isEmpty(items) ? [] : items.map(item => item[field]);
};

// Prevent Enter and Numpad Enter from submitting the form. We do this to force the user to click
// on the Submit button explicitly.
//TODO remove when no longer needed - i.e. everything is using ZodForm
export const checkKeyDown = (e: any) => {
	if (e.code === 'Enter' || e.code === 'NumpadEnter') {
		if (e.target.tagName !== 'TEXTAREA') e.preventDefault();
	}
};

// On Enter and Numpad Enter, calls callback function.
// if cb not presented, prevent default func of Enter/NumpadEnter.
export const onEnter = (e: any, callback?: () => void) => {
	if (e.code === 'Enter' || e.code === 'NumpadEnter') callback ? callback() : e.preventDefault();
};

export const intersect = <T>(a: T[], b: T[], isEqual: (a: T, b: T) => boolean): T[] => {
	if (isNil(a) || isEmpty(a) || isNil(b) || isEmpty(b)) return [];
	return a.filter(aval => b.some(bval => isEqual(aval, bval)));
};

export const toNumber = (numStr: string | null | undefined): number | undefined => {
	const num = Number(numStr);
	return isNaN(num) ? undefined : num;
};

/**
 * Filter and sort array (in ascending order aka alphabetically) by a given property.
 * @param array Array of items to filter and sort.
 * @param search User input search string
 * @param keys Items property to filter/sort on.
 * @returns An array of items filtered by search string and sorted in ascending order.
 */
export function filterAndSortArrayByProperty<T>(array: T[], search: string, keys: (keyof T)[]): T[] {
	const filteredItems = array.filter(item =>
		keys.some(key => String(item[key]).toLowerCase().includes(search.toLowerCase()))
	);

	return sortAscBy(filteredItems, keys[0]);
}

/**
 * Converts null to undefined, simplifying the handling of absent values.
 *
 * @param value - Nullable value, of any type
 * @returns The original value if not null, otherwise undefined
 */
export function valueOrUndefined<T>(value: T | null): T | undefined {
	return value === null ? undefined : value;
}
