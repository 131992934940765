import _ from 'lodash';

/**
 * Checks if `object` is `null` or `undefined`.
 * @param object Object to check.
 * @returns True if `object` is `null` or `undefined`.
 */
export const isNil = (object: any): object is null | undefined => {
	return isNullOrUndefined(object);
};

// Deprecated, prefer isNil for brevity.
export const isNullOrUndefined = (object: any): object is null | undefined => {
	return object === null || object === undefined;
};

export const hasText = (text: string | null | undefined) => {
	if (isNullOrUndefined(text)) return false;
	const trimmedText = text.trim();
	if (trimmedText.length === 0) return false;
	return true;
};

export const isString = (text: any): text is string => {
	return typeof text === 'string' || text instanceof String;
};

export const isNumber = (text: any): text is number => {
	return !isNaN(text) && (typeof text === 'number' || text instanceof Number);
};

export const isObject = (item: any): item is object => {
	return !isString(item) && !isNumber(item) && _.isObject(item);
};

export const hasIdField = (item: any): item is { id: any } => {
	return isObject(item) && hasField(item, 'id');
};

export const hasValueField = (item: any): item is { value: any } => {
	return isObject(item) && hasField(item, 'value');
};

export const hasField = (item: any, field: string): boolean => {
	return Object.hasOwn(item, field);
};

export const isArray = (object: any): object is any[] => {
	return Array.isArray(object);
};
