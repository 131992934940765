import { AxiosInstance } from 'axios';
import { isNil } from 'lodash';
import { differenceInMilliseconds, format } from 'date-fns';

const freesiDebugHeader = 'X-FREESI-DEBUG';

export function addLoggingInterceptors(axios: AxiosInstance) {
	axios.interceptors.request.use(
		config => {
			if (isNil(config.headers)) {
				config.headers = {};
			}
			config.headers[freesiDebugHeader] = Date.now();
			return config;
		},
		error => {
			console.error(error);
			return Promise.reject(error);
		},
		{ synchronous: true }
	);
	axios.interceptors.response.use(
		response => {
			const stopTime = Date.now();
			const startTime = !isNil(response.config.headers) ? Number(response.config.headers[freesiDebugHeader]) : stopTime;
			const timestamp = format(Date.now(), 'HH:mm:ss.SSS');
			const duration = differenceInMilliseconds(stopTime, startTime).toString();
			const method = response.config.method?.toUpperCase();
			const url = `${response.config.baseURL?.toLowerCase()}${response.config.url?.toLowerCase()}`;
			console.debug(`${timestamp} [${duration.padStart(4, ' ')} ms] ${method} ${url} - ${response.status}`, response);
			return response;
		},
		error => {
			console.error(error);
			return Promise.reject(error);
		},
		{ synchronous: true }
	);
}
