function isObject(item: any): item is Record<string, unknown> {
    return item && typeof item === 'object' && !Array.isArray(item);
}

export function deepMerge<A extends Record<string, unknown>, B extends Record<string, unknown>>(obj1: A, obj2: B): A&B {
    let output: Record<string, unknown> = { ...obj1 };

    for (let key in obj2) {
        if (isObject(obj2[key]) && obj1.hasOwnProperty(key) && isObject(obj1[key])) {
            output[key] = deepMerge(obj1[key] as Record<string, unknown>, obj2[key] as Record<string, unknown>);
        } else if (!obj1.hasOwnProperty(key)) {
            output[key] = obj2[key];
        }
    }

    return output as A&B;
}