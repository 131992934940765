import { useEffect } from 'react';

export function useTimeout(callback: () => void, delay: number, deps: unknown[] = []): void {
	useEffect(() => {
		const timer = setTimeout(callback, delay);
		return () => {
			clearTimeout(timer);
		};
	}, [delay, ...deps]);
}
