import { useMemo } from 'react';
import { AnalyticsFacade } from './AnalyticsFacade';
import { useSessionStore } from '../stores/session/SessionStore';

export function useAnalytics() {
	const { currentUser: user, currentRole: role, activeOrganization, currentLocale: locale } = useSessionStore();
	const analytics = useMemo(
		() =>
			new AnalyticsFacade({
				user,
				role,
				locale,
				activeOrganization
			}),
		[user, role, locale, activeOrganization]
	);
	return analytics;
}
