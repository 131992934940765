import React, { lazy, Suspense, useMemo } from 'react';
import { keycloakClientFactory } from './api/apiSettings';
import { useRedirect } from './hooks/useRedirect';
import { getSessionStoreState } from './stores/session/SessionStore';
import { useTrackPageChange } from './analytics/useTrackPageChange';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import DelaySpinner from './components/delay-spinner/DelaySpinner';

const ExternalAppRouter = lazy(() => import('./ExternalAppRouter'));
const AppRouter = lazy(() => import('./AppRouter'));

export function App() {
	// TODO: Remove when it is safe to assume that no traffic will hit legacy hosts.
	// Temporary redirection mechanism to redirect from classic url to fc url.
	// Why? Because Azure makes simple things hard.
	useRedirect();
	const keycloakClient = useMemo(() => {
		const currentPath = window.location.pathname;
		const externalPathPattern = /^\/external(\/[a-zA-Z0-9-]+)?$/;

		if (externalPathPattern.test(currentPath)) {
			return null;
		}
		return keycloakClientFactory();
	}, []);
	useTrackPageChange();

	return (
		<Suspense fallback={<DelaySpinner type="default" delay={300} />}>
			{keycloakClient ?
				<ReactKeycloakProvider
					authClient={keycloakClient}
					initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
					onTokens={({ token }) => {
						getSessionStoreState().setToken(token);
						void getSessionStoreState().refreshUser();
					}}>
					<AppRouter />
				</ReactKeycloakProvider>
				: <ExternalAppRouter />}
		</Suspense>
	);
}
