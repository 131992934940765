import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import '../src/sass/main.scss';
import { App } from './App';

import { unregister } from './serviceWorker';
import { ThemeProvider } from '@mui/material/styles';
import { FlagsProvider } from 'flagged';
import { buildFeatureFlags } from './featureConfiguration';
import { CssBaseline } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import materialUITheme from './brands/materialUITheme';
import { BrowserRouter } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import './i18n';
import DelaySpinner from './components/delay-spinner/DelaySpinner';
import { loadGoogleAnalytics } from './analytics/loadGoogleAnalytics';
//TODO this has been here since 2021 and can prob be removed now?
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const flags = buildFeatureFlags();

const container = document.getElementById('root');
if (!container) throw new Error("Container element with id 'root' not found");
const root = createRoot(container);

loadGoogleAnalytics();
root.render(
	<FlagsProvider features={flags}>
		<ThemeProvider theme={materialUITheme}>
			<CssBaseline />
			<Suspense fallback={<DelaySpinner />}>
				<BrowserRouter>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<App />
					</LocalizationProvider>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	</FlagsProvider>
);

// Unregister service worker
unregister();
