import Keycloak from 'keycloak-js';
import { isEmpty } from '../utils/helperFunctions';

/**
 * FreesiServices type includes the supported service names that are used in axios queries.
 * For example, 'GET http://<host>/<freesiservice>/<resource>'.
 */
export type FreesiService = 'access' | 'cloud' | 'datascience' | 'timeseries';

/**
 * Factory function type for creating new Keycloak clients.
 *
 * @param settings Settings used for configuring the client. Not providing settings should cause implementations to fallback on default settings.
 * @returns Keycloak client.
 */
export type KeycloakClientFactory = (settings?: ApiSettings) => Keycloak.KeycloakInstance;

/**
 * ApiSettings configuration object
 */
export type ApiSettings = {
	/**
	 * Login api base url.
	 */
	loginBaseUrl: string;
	/**
	 * Base api url for most end-point requests.
	 */
	apiBaseUrl: string;
	/**
	 * Base api url for /access/users end-point requests.
	 *
	 * NOTE!!! This is to be used only for requests that handle IAM activity in keycloak so the server can redirect the user back to the correct sub-domain
	 */
	usersApiBaseUrl: string;
	/**
	 * When true, API calls made with freesiAxiosFactory apis log all requests and response to console.
	 */
	apiCallLoggingEnabled: boolean;
	/**
	 * Current application tenant. The value is set to the brand specific subdomain name.
	 * For example, for 'http://sol.cloud.freesi.io' the value is 'sol'.
	 */
	tenant: string | undefined;
	/**
	 * Keycloak client id of the current tenant.
	 */
	clientId: string;
};

/**
 * Freesi hostnames are of the form <environment>.freesi.io. They always consist of these three parts: environment, 'freesi' and 'io'.
 */
const FREESI_HOSTNAME_PART_COUNT = 3;

/**
 * Extracts the brand subdomain name from the given url.
 *
 * @param href Current page url.
 * @returns Extracted brand subdomain name OR undefined if the given url does not contain a brand subdomain.
 */
const extractBrandSubDomain = (href: string) => {
	try {
		const url = new URL(href);
		const parts = url.host.split('.');
		// Brand hostnames are always assumed to be of form <brand>.<environment>.freesi.io
		return parts.length === FREESI_HOSTNAME_PART_COUNT + 1 ? parts[0] : undefined;
	} catch {
		return undefined;
	}
};

const protocol = (): string => {
	return process.env.REACT_APP_USE_SSL === 'true' ? 'https' : 'http';
};

/**
 * Creates the api settings configuration object based on env variable values.
 *
 * @param tenant Tenant name. The same as the brand subdomain name.
 * @returns ApiSettings configuration object.
 */
export const apiSettingsFactory = (tenant?: string): ApiSettings => {
	let loginUrl: string;
	let usersApiUrl: string;
	let clientId: string;
	const proto = protocol();
	if (isEmpty(tenant)) {
		loginUrl = `${proto}://${process.env.REACT_APP_KEYCLOAK_HOST}/${process.env.REACT_APP_LOGIN_BASE}`;
		usersApiUrl = `${proto}://${process.env.REACT_APP_KEYCLOAK_HOST}/${process.env.REACT_APP_API_BASE}`;
		clientId = 'freesicloud-frontend';
	} else {
		loginUrl = `${proto}://${tenant}.${process.env.REACT_APP_KEYCLOAK_HOST}/${process.env.REACT_APP_LOGIN_BASE}`;
		usersApiUrl = `${proto}://${tenant}.${process.env.REACT_APP_KEYCLOAK_HOST}/${process.env.REACT_APP_API_BASE}`;
		clientId = `${tenant}-freesicloud-frontend`;
	}
	return {
		loginBaseUrl: loginUrl,
		apiBaseUrl: `${proto}://${process.env.REACT_APP_KEYCLOAK_HOST}/${process.env.REACT_APP_API_BASE}`,
		usersApiBaseUrl: usersApiUrl,
		apiCallLoggingEnabled: process.env.REACT_APP_LOG_API_CALLS === 'true',
		tenant: tenant,
		clientId: clientId
	};
};

const tenant = extractBrandSubDomain(window.location.href);

/**
 * Default api settings.
 */
export const apiSettings = apiSettingsFactory(tenant);

/**
 * Factory function for creating Keycloak client instances. By default, uses env variable based api settings.
 * Default settings can be overridden by providing an ApiSettings object in the factory function call.
 *
 * @param settings Settings used for overriding env variable based configuration.
 * @returns Keycloak client.
 */
export const keycloakClientFactory: KeycloakClientFactory = (settings?: ApiSettings) => {
	const effectiveSettings = settings ?? apiSettings;
	const url = effectiveSettings.loginBaseUrl;
	const clientId = effectiveSettings.clientId;
	return Keycloak({
		realm: 'freesi',
		url: url,
		clientId: clientId
	});
};
