import { UserModel } from '../models/User';
import { RoleType } from '../models/Role';
import { SupportedLocale } from '../models/LocalizedString';
import { SimpleOrganizationModel } from '../models/Organization';
import { AnalyticsEvent, AnalyticsEventType } from './AnalyticsEvent';
import { AnalyticsService } from './AnalyticsService';
import { GoogleAnalyticsService } from './GoogleAnalyticsService';
import { DummyAnalyticsService } from './DummyAnalyticsService';

export class AnalyticsFacade {
	private readonly user?: UserModel;
	private readonly role?: RoleType;
	private readonly locale?: SupportedLocale;
	private readonly activeOrganization?: SimpleOrganizationModel;
	private readonly analyticsService: AnalyticsService;

	constructor({
		user,
		role,
		locale,
		activeOrganization
	}: {
		user?: UserModel;
		role?: RoleType;
		locale?: SupportedLocale;
		activeOrganization?: SimpleOrganizationModel;
	}) {
		this.analyticsService = createAnalyticsService();
		this.user = user;
		this.role = role;
		this.locale = locale;
		this.activeOrganization = activeOrganization;
	}

	trackEvent(type: AnalyticsEventType, data?: any) {
		const event: AnalyticsEvent = {
			type,
			data,
			user: this.user?.id,
			role: this.role,
			locale: this.locale,
			activeOrganization: this.activeOrganization?.name
		};
		this.analyticsService.trackEvent(event);
	}

	trackPageChange(pathName: string) {
		this.trackEvent('page_change', pathName);
	}

	trackTabChange(newTab: string) {
		this.trackEvent('tab_change', newTab);
	}
}

function createAnalyticsService(): AnalyticsService {
	try {
		if (process.env.REACT_APP_GA_TRACKING_ID) return new GoogleAnalyticsService();
	} catch (e) {}
	console.warn('no analytics implementation available, defaulting to dummy');
	return new DummyAnalyticsService();
}
