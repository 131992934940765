import { isEmpty } from '../utils/helperFunctions';
import { isNil } from '../utils/typeGuards';
import defaultFreesi from './_default/styles.json';
import defaultFreesiLogo from './_default/logo.svg'
import externalUserLoginBackground from '../../src/images/external-user-login-bg.svg';
import _ from 'lodash';
import { Theme, ThemeOverride } from './Theme';

/**
 * Sets/overwrites document styles based on a given theme.
 * @param theme Themes whose value will be used to overwrite document styles.
 */
export const setDocumentStyles = (theme: Theme): void => {
	const colors = theme.colors;
	const style = document.documentElement.style;
	style.setProperty('--brand-base', colors.base);
	style.setProperty('--brand-paragraphAndDescription', colors.paragraphAndDescription);
	style.setProperty('--brand-card-backgroundColor', colors.card.backgroundColor);
	style.setProperty('--brand-card-textColorType', colors.card.textColorType);
	style.setProperty('--brand-card-textColorTitle', colors.card.textColorTitle);
	style.setProperty('--brand-card-textColorTitleScorePoints', colors.card.textColorTitleScorePoints);
	style.setProperty('--brand-card-textColorTitleChange', colors.card.textColorTitleChange);
	style.setProperty('--brand-card-scoreArrowUp', colors.card.scoreArrowUp);
	style.setProperty('--brand-card-scoreChangeValue', colors.card.scoreChangeValue);
	style.setProperty('--brand-card-userScoreGood', colors.card.userScoreGood);
	style.setProperty('--brand-card-userScoreOk', colors.card.userScoreOk);
	style.setProperty('--brand-card-userScoreBad', colors.card.userScoreBad);
	style.setProperty('--brand-card-footerBackgroundColor', colors.card.footerBackgroundColor);
	style.setProperty('--brand-card-footerColorText', colors.card.footerColorText);
	style.setProperty('--brand-card-footerBorderTop', colors.card.footerBorderTop!);
	style.setProperty('--brand-card-backgroundColorHovered', colors.card.hover.backgroundColor);
	style.setProperty('--brand-card-textColorTypeHovered', colors.card.hover.textColorType);
	style.setProperty('--brand-card-textColorTitleHovered', colors.card.hover.textColorTitle);
	style.setProperty('--brand-card-textColorTitleScorePointsHovered', colors.card.hover.textColorTitleScorePoints);
	style.setProperty('--brand-card-textColorTitleChangeHovered', colors.card.hover.textColorTitleChange);
	style.setProperty('--brand-card-scoreArrowUpHovered', colors.card.hover.scoreArrowUp);
	style.setProperty('--brand-card-scoreChangeValueHovered', colors.card.hover.scoreChangeValue);
	style.setProperty('--brand-card-userScoreGoodHovered', colors.card.hover.userScoreGood);
	style.setProperty('--brand-card-userScoreOkHovered', colors.card.hover.userScoreOk);
	style.setProperty('--brand-card-userScoreBadHovered', colors.card.hover.userScoreBad);
	style.setProperty('--brand-card-footerBackgroundColorHovered', colors.card.hover.footerBackgroundColor);
	style.setProperty('--brand-card-footerColorTextHovered', colors.card.hover.footerColorText);
	style.setProperty('--brand-card-footerBorderTopHovered', colors.card.hover.footerBorderTop!);
	style.setProperty('--brand-icon-locations', colors.icon.location);
	style.setProperty('--brand-icon-premises', colors.icon.premise);
};

export const defaultTheme: Theme = defaultFreesi;
defaultTheme.logo = defaultFreesiLogo;
defaultTheme.externalUserLoginBackground = externalUserLoginBackground;

export enum SupportedTheme {
	Freesi = 'freesi',
	Sol = 'sol',
	Siemens = 'siemens',
	Uniqair = 'uniqair'
}

export const buildTheme = async (themeKey?: SupportedTheme): Promise<Theme> => {
	if (isNil(themeKey) || isEmpty(themeKey) || themeKey === SupportedTheme.Freesi) {
		return defaultTheme;
	}

	const supportedThemes = Object.values(SupportedTheme);
	if (!supportedThemes.includes(themeKey)) {
		console.error(`Unsupported theme ${themeKey}`);
		return defaultTheme;
	}

	const override: ThemeOverride = (await import(`./${themeKey}/styles.json`)).default;
	const logo: string = (await import(`./${themeKey}/logo.svg`)).default;
	override.logo = logo;
	return _.merge(defaultTheme, override);
};
